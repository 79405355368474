// 开发环境域名

const hostDevelopment = 'https://lsshop.yunxin360.cn'

export default {
  // 版本
  version: '1.9.0',
  baseURL: process.env.NODE_ENV === 'production' ? '' : hostDevelopment,
  tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
